import config, { adminPrefix, getIsMaintenanceActive } from "~/config";
import { AdminToolRoles } from "~/utils/rolesHelper";

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (getIsMaintenanceActive()) {
    return navigateTo(config.maintenancePage);
  }

  if (import.meta.client) {
    const authStore = useAdminAuthStore();
    const orderStore = useOrderStore();
    const { $adminAuth } = useNuxtApp();

    const adminBookingRoute = adminPrefix("neukunde");
    if (
      from.path.startsWith(adminBookingRoute) &&
      !to.path.startsWith(adminBookingRoute)
    ) {
      // Reset the order store once the admin navigates away from the "create new customer" section:
      orderStore.reset();
    }

    if (!authStore.loggedIn) {
      await $adminAuth.signIn(to.fullPath);
    }

    // Only allow navigaton to the page, if the user has the required role:
    const allowedRoutes = (to.meta?.allowedRoles as AdminToolRoles[]) || [];
    if (
      allowedRoutes.length > 0 &&
      ![...allowedRoutes, AdminToolRoles.ReadWriteAll].some((r) => authStore.hasRole(r))
    ) {
      // Navigate to "from" page = do nothing.
      return navigateTo(from.path);
    }
  }
});
